import SettingsWrapper from "common/ScrollableWrapper/SettingsWrapper";
import { CSSProperties, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form7";
import SettingsInput from "./SettingsInput";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ColorPicker from "components/Square/SquareColorPicker";
import { ControlLabel } from "react-bootstrap";
import toastr from "toastr";
import "./WidgetSettings.scss";
import { createCssString } from "helpers/text.helper";
import {
  WIDGET_STYLE,
  WIDGET_STYLE_CSS,
  WIDGET_TYPE,
  WidgetModeOptions,
  WidgetStyleOptions,
  WidgetTypeOptions
} from "constants/widgets";
import { Widget, WidgetProps } from "@joerozek/waitly-widgets";
import cx from "classnames";
import { useMediaQuery } from "hooks/useMediaQuery";
import { environment } from "configs/environment";
import { Business } from "types/business";
import { getBusinessResources } from "services/businesses/businesses.service";
import { DEFAULT_RESOURCE_COUNT } from "components/Square/SquareReservationSetup";

type IForm = {
  name: string;
  type: "resButton" | "waitlist" | "resForm";
  location: string;
  mode: "overlay" | "tab";
  style: string;
  fontColor: string;
  buttonColor: string;
  shadow: boolean;
};

enum BusinessPlan {
  FREE = "free",
  PRO = "pro",
  ENTERPRISE = "enterprise",
  PREMIUM = "premium"
}

const WidgetSettings = ({ plan }: { plan: BusinessPlan }) => {
  const shortId = () => {
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let autoId = "";
    for (let i = 0; i < 7; i++) {
      autoId += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return autoId;
  };

  const businessSelector = useSelector(state => (state as any).business);
  const { businesses, business }: { businesses: Business[]; business: any } = businessSelector;
  const { account } = useSelector(state => (state as any).account || {});
  const prices = useSelector(state => (state as any).prices.prices) as any;
  const [widgetId, setWidgetId] = useState(shortId());
  const isMobile = useMediaQuery("(max-width: 1024px)");
  const {
    handleSubmit,
    formState: { errors },
    ...formMethods
  } = useForm<IForm>({
    defaultValues: {
      name: "",
      type: WidgetTypeOptions[0].value,
      location: "",
      mode: WidgetModeOptions[0].value,
      style: WidgetStyleOptions[0].value,
      fontColor: "#fff",
      buttonColor: "#000",
      shadow: true
    }
  });

  const isFeatureEnabled = plan && plan !== BusinessPlan.FREE && plan !== BusinessPlan.PREMIUM;
  const isFree = plan === BusinessPlan.FREE;
  const isIOSOrigin = account.origin === "ios";
  const isFreeTrialAvailable = prices && prices.trialEligible && plan && isFree;
  const minPartySize = business.settings.reservations.minPartySize || 1;
  const [maxResourcesCount, setMaxResourcesCount] = useState(DEFAULT_RESOURCE_COUNT);

  const form = formMethods.watch();

  const businessOptions = useMemo<{ text: string; value: string }[]>(() => {
    const options = businesses
      ?.filter(business =>
        form.type === WIDGET_TYPE.RESERVATION || form.type === WIDGET_TYPE.RESERVATION_FORM
          ? business.settings.selfBooking
          : form.type === WIDGET_TYPE.WAITLIST
          ? business.settings.selfCheckIn
          : false
      )
      .map(business => ({
        text: business.name,
        value: business.businessId
      }));

    return [...(options || [])];
  }, [businesses, form.type]);

  useEffect(() => {
    if (!businessOptions?.some(o => o.value === form.location)) {
      if (businessOptions?.length) {
        formMethods.setValue("location", businessOptions[0].value);
      } else {
        formMethods.setValue("location", "");
      }
    } else if (businessOptions?.length) {
      formMethods.setValue("location", businessOptions[0].value);
    }
  }, [form.type, businessOptions]);

  const fetchSquareResources = async (withLoading = true) => {
    try {
      if (!business.businessId) throw new Error("Bussiness id is required");
      const reslt = await getBusinessResources(business.businessId);
      if (!reslt || !reslt.docs) throw new Error("Something went wrong, when fetch resources");
      const resorcs = reslt?.docs
        .map(r => r.data())
        .filter(r => r.reservationsType === "reservations")
        .sort((a, b) => a.maxPartySize - b.maxPartySize);
      const maxRsrcCount = Math.max(...resorcs.map(r => r.maxPartySize), 0) || 0;
      if (maxRsrcCount) {
        setMaxResourcesCount(maxRsrcCount);
      } else {
        setMaxResourcesCount(DEFAULT_RESOURCE_COUNT);
      }
    } catch (e) {
      console.error(e);
      setMaxResourcesCount(DEFAULT_RESOURCE_COUNT);
    }
  };

  useEffect(() => {
    fetchSquareResources();
  }, [business.businessId]);

  useEffect(() => {
    formMethods.setValue("name", getDefaultButtonTextByType(form.type));
    if (form.type === WIDGET_TYPE.RESERVATION_FORM) {
      const style = formMethods.watch("style");
      if (style === WIDGET_STYLE.CUSTOM) {
        formMethods.setValue("style", WidgetStyleOptions[0].value);
      }
    }
  }, [form.type]);

  const getDefaultButtonTextByType = (type: WIDGET_TYPE | string) => {
    switch (type) {
      case WIDGET_TYPE.RESERVATION:
        return "Make a reservation";
      case WIDGET_TYPE.WAITLIST:
        return "Add me to the waitlist";
      case WIDGET_TYPE.RESERVATION_FORM:
        return "Make a reservation";
      default:
        return "";
    }
  };

  const onChangeFormField = event => {
    setWidgetId(shortId());
    formMethods.setValue(event.target.name, event.target.value);
  };

  const copySnippetToClipboard = async () => {
    if (!isFeatureEnabled) return;
    handleSubmit(() => {
      navigator.clipboard.writeText(snippetContent).then(function() {
        toastr.success(`Copied To Clipboard`);
      });
    })();
  };

  const buttonStyle: CSSProperties =
    form.style === WIDGET_STYLE.CUSTOM
      ? {}
      : {
          width: "fit-content",
          ...WIDGET_STYLE_CSS[form.style as string],
          borderWidth: "0px",
          color: form.fontColor,
          backgroundColor: form.buttonColor,
          boxShadow: form.shadow ? "rgba(0, 0, 0, 0.24) 0px 3px 8px" : "none",
          cursor: "pointer",
          letterSpacing: "normal",
          fontFamily: "Roboto, Arial, sans-serif",
          margin: "20px",
          textDecoration: "none",
          fontSize: "17px",
          border: "none",
          padding: "10px 20px"
        };

  const snippetContent = `<!-- to include your own css style add data-button-class attribute to div element and include your button class name. -->
<div
  class="${isFeatureEnabled ? "waitly-widget" : "..."}"
  id="${isFeatureEnabled ? `waitly-waitlist-widget-${widgetId}` : "..."}"
  data-type="${form.type}"
  data-bid="${form.location}"
  data-title="${form.name}"
  data-mode="${form.mode}"${
    form.type === WIDGET_TYPE.RESERVATION_FORM
      ? `\n  data-min-party-size="${minPartySize}"\n  data-max-party-size="${maxResourcesCount}"`
      : ""
  }
  data-button-class=""
  data-style="${createCssString({ ...buttonStyle } as any)}"
></div>
<script src="${isFeatureEnabled ? `${environment.waitlistUrl}static/js/widget.js` : "..."}" ></script>
<script>
  window.initWaitlyWidget("${isFeatureEnabled ? `waitly-waitlist-widget-${widgetId}` : "..."}");
</script>`;

  const widgetProps: WidgetProps = {
    bid: form.location,
    buttonClass: "",
    mode: form.mode,
    style: createCssString({ ...buttonStyle } as any),
    title: form.name || " ",
    type: form.type,
    host: environment.waitlistUrl
  };

  return (
    <SettingsWrapper title={"Widgets"} handleSubmit={() => null} buttonStyles={{ maxWidth: 600 }} hideSubmit>
      {!isFeatureEnabled && (!isIOSOrigin || isFree) && (
        <div className="settings-subtitle self-check-wrapper">
          Website Widgets are a Waitly Professional feature
          <Link to="/admin/upgrade" style={{ fontWeight: "700" }}>
            {isFreeTrialAvailable ? "Start Free Trial" : "Upgrade now"}
          </Link>
        </div>
      )}
      {!isFeatureEnabled && isIOSOrigin && !isFree && (
        <div className="settings-subtitle self-check-wrapper">
          Since your Waitly Premium Subscription is through Apple, you'll need to upgrade to Waitly Professional from
          your Apple Device.
          <a
            href="https://support.apple.com/en-us/HT204939"
            rel="noopener noreferrer"
            target="_blank"
            style={{ fontWeight: "700" }}
          >
            (learn more)
          </a>
        </div>
      )}
      <div
        className={cx({
          "d-flex justify-content-between w-100": true,
          "flex-column": isMobile
        })}
      >
        <div className="d-flex flex-column">
          <Controller
            name={"type"}
            control={formMethods.control}
            rules={{
              required: true
            }}
            render={() => (
              <SettingsInput
                name={"type"}
                type={"select"}
                enabled={isFeatureEnabled}
                value={form.type || ""}
                options={WidgetTypeOptions}
                onChangeValue={onChangeFormField}
                errors={errors}
                label={"Type"}
                removeDefaultStyle
              />
            )}
          />

          <Controller
            name={"location"}
            control={formMethods.control}
            rules={{
              required: true
            }}
            render={() => (
              <SettingsInput
                name={"location"}
                type={"select"}
                enabled={isFeatureEnabled}
                value={form.location || ""}
                options={businessOptions}
                onChangeValue={onChangeFormField}
                errors={errors}
                label={"Location"}
                removeDefaultStyle
              />
            )}
          />

          {!!form.type && businessOptions?.length <= 0 && (
            <div className="mt-3 ml-2">
              No locations with {form.type === WIDGET_TYPE.WAITLIST ? "Self Check-in" : "Online Reservations"}. Please
              enable this feature first.{" "}
              <Link
                to={
                  form.type === WIDGET_TYPE.WAITLIST
                    ? "/admin/settings?page=self-check-in"
                    : "/admin/settings?page=reservations"
                }
              >
                Show me
              </Link>
            </div>
          )}
          <Controller
            name={"name"}
            control={formMethods.control}
            rules={{
              required: true,
              maxLength: {
                value: 25,
                message: "Name length should be less than 25 characters"
              }
            }}
            render={() => (
              <SettingsInput
                name="name"
                type={"text"}
                enabled={isFeatureEnabled}
                errors={errors}
                label={formMethods.watch("type") === WIDGET_TYPE.RESERVATION_FORM ? "Title" : "Button Title"}
                value={form.name}
                onChangeValue={onChangeFormField}
                removeDefaultStyle
              />
            )}
          />
          <Controller
            name={"mode"}
            control={formMethods.control}
            rules={{
              required: true
            }}
            render={() => (
              <SettingsInput
                name={"mode"}
                type={"select"}
                enabled={isFeatureEnabled}
                value={form.mode || ""}
                options={WidgetModeOptions}
                onChangeValue={onChangeFormField}
                errors={errors}
                label={"Click Behavior"}
                removeDefaultStyle
              />
            )}
          />
          <Controller
            name={"style"}
            control={formMethods.control}
            rules={{
              required: true
            }}
            render={() => (
              <SettingsInput
                name={"style"}
                type={"select"}
                enabled={isFeatureEnabled}
                value={form.style || ""}
                options={[...WidgetStyleOptions].filter(o =>
                  formMethods.watch("type") === WIDGET_TYPE.RESERVATION_FORM ? o.value !== WIDGET_STYLE.CUSTOM : true
                )}
                onChangeValue={onChangeFormField}
                errors={errors}
                label={"Style"}
                removeDefaultStyle
              />
            )}
          />
          {form.style === WIDGET_STYLE.CUSTOM && (
            <div className="mt-3 mx-1">
              Use <code>data-style</code> or <code>data-button-class</code> attributes to style the button.
            </div>
          )}

          {form.style !== WIDGET_STYLE.CUSTOM && (
            <div className="w-100">
              <div className="d-flex align-items-center mt-2 mb-4 justify-content-between w-100 pr-2">
                <ControlLabel className="settings-label mt-0" style={{ color: !isFeatureEnabled && "#CAC9C9" }}>
                  Font Color
                </ControlLabel>
                <Controller
                  name="fontColor"
                  control={formMethods.control}
                  render={() => (
                    <ColorPicker
                      position="top"
                      color={form.fontColor}
                      changeColor={fontColor => formMethods.setValue("fontColor", fontColor)}
                      disabled={!isFeatureEnabled}
                    />
                  )}
                />
              </div>
              <div className="d-flex align-items-center mb-2 justify-content-between w-100 pr-2 ">
                <ControlLabel className="settings-label mt-0" style={{ color: !isFeatureEnabled && "#CAC9C9" }}>
                  Button Color
                </ControlLabel>
                <Controller
                  name="buttonColor"
                  control={formMethods.control}
                  render={() => (
                    <ColorPicker
                      position="top"
                      color={form.buttonColor}
                      changeColor={buttonColor => formMethods.setValue("buttonColor", buttonColor)}
                      disabled={!isFeatureEnabled}
                    />
                  )}
                />
              </div>
              <div className="d-flex align-items-center justify-content-between w-100">
                <ControlLabel className="settings-label mt-0" style={{ color: !isFeatureEnabled && "#CAC9C9" }}>
                  Shadow
                </ControlLabel>
                <Controller
                  name="shadow"
                  control={formMethods.control}
                  render={() => (
                    <SettingsInput
                      name={"shadow"}
                      type={"switch"}
                      enabled={isFeatureEnabled}
                      className="mb-0 mr-0"
                      value={form.shadow}
                      hideLabel={true}
                      onChangeValue={event => {
                        formMethods.setValue("shadow", event.target.checked);
                      }}
                    />
                  )}
                />
              </div>
            </div>
          )}
        </div>
        <div
          className={cx({
            "d-flex flex-column gap-4 mt-4 mr-2 ml-2": true,
            "w-50": !isMobile
          })}
        >
          <div className="widget-button-preview">
            <div className="widget-button-preview__title">Click to Preview</div>
            {isFeatureEnabled ? (
              <Widget
                {...widgetProps}
                {...(formMethods.watch("type") === WIDGET_TYPE.RESERVATION_FORM
                  ? {
                      minPartySize: String(minPartySize),
                      maxPartySize: String(maxResourcesCount)
                    }
                  : {})}
              />
            ) : (
              <button type="button" style={{ ...buttonStyle, cursor: "not-allowed" }} disabled>
                {widgetProps.title || "Button Title"}
              </button>
            )}
          </div>
          <div
            onClick={copySnippetToClipboard}
            className={cx({
              "cursor-pointer align-items-center gap-2 w-fit-content": true,
              "d-flex ": isFeatureEnabled,
              "d-none": !isFeatureEnabled
            })}
          >
            <i className="mdi mdi-24px mdi-clipboard-text" />
            <span>Copy to clipboard</span>
          </div>
          <pre onClick={copySnippetToClipboard}>
            <code
              className="white-space-pre-wrap"
              style={{
                // that's made to prevent text selection and allow to copying only from btn, if all data is selected correctly
                userSelect: "none"
              }}
            >
              {snippetContent}
            </code>
          </pre>
        </div>
      </div>
    </SettingsWrapper>
  );
};

export default WidgetSettings;
